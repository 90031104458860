import React, { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import clsx from "clsx";
import { DialogContent, DialogActions, Grow } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { TransitionProps } from "@material-ui/core/transitions";
import KirdModal from "components/common/KirdModal";
import styles from "./cdrPopup.module.css";
import {gql, useLazyQuery, useMutation} from "@apollo/client";

// 2depth 역량 조회
const GET_NATIVE_CLASSIFICATION_COMPETENCY_2DEPTH_LIST = gql`
  query GetNativeClassificationCompetency2DepthList($request: GetClassificationCompetencyListInput) {
    classifications: getNativeClassificationCompetency2DepthList(request: $request) {
      classifications{
        depth1Id
        depth1Name
        depth1Year
        depth1EmojiFile {
          id
          name: originFileName
          contentType
          size
        }
        depth2List{
          depth2Id
          depth2Name
          depth2Desc
          isInterest
        }
      }
    }
  }
`;

const TOGGLE_MEMBER_CLASSIFICATION_INTEREST = gql`
  mutation ToggleMemberClassificationInterest($request: ToggleMemberClassificationInterestInput!) {
    MemberMutationResponse: toggleMemberClassificationInterest(request: $request){
      id
    }
  }
`;

const Transition = forwardRef(function Transition(props: TransitionProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) {
  return <Grow in={true} ref={ref} {...props} timeout={{ appear: 0, enter: 500, exit: 200 }} />;
});

interface Props {
  open: boolean;
  onClose: Function;
  fullWidth?: boolean;
  maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl";
  memberId: string;
}

// 2 depth
interface ClassificationItem {
  depth2Id: string;
  depth2Name: string;
  isInterest?: boolean;
  depth2Desc?: string;
  isVisible?: boolean;
  isChecked?: boolean;
  isInterestNew?: boolean; // 새롭게 관심 역량으로 추가되었는지에 대한 정보. toggle이기 때문에 기존의 관심 역량과 분리가 필요하여 신규 생성.
}

// 1 depth
interface ClassificationDepthResponse {
  depth1Name: string;
  depth1Id?: string;
  depth1CompanyGroupId?: string;
  depth1Year: number;
//   depth1EmojiFile?: FileResponse;
  depth2List: ClassificationItemMap;
}

type ClassificationItemMap = Map<string, ClassificationItem>; // depth2List
type ClassificationMap = Map<string, ClassificationDepthResponse>; // dpeth1List

export default function MyCdrPopup(props: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { open, onClose, memberId, ...other } = props;
  // const { query, isReady } = useRouter();
  const [depth1List, setDepth1List] = useState(new Map()); // Depth 1 List
  const [depth1Name, setDepth1Name] = useState(); // Depth 1 Name
  const [depth2List, setDepth2List] = useState(new Map()); // Depth 2 List
  const [isAllChecked, setIsAllChecked] = useState(false); // All Check
  const [myInterestCptc, setMyInterestCptc] = useState(new Map()); // 나의 관심 역량
  
  // 체크 후, 담기 전인 상태의 역량 ID값이 들어갈 공간
  // submit을 했을 때 활성화된 checkbox의 값만 가져왔기 때문에
  // 활성화되었었지만 비활성화로 바뀐 checkbox의 정보도 가져오기 위하여 생성됨
  const [newCheckList, setNewCheckList] = useState([]);

  // 2depth 역량 조회
  // 1Depth 역량 하위에 2Depth 역량들이 배열로 구성되어있는 형태
  const [fnGetNativeClassificationCompetency2DepthList] = useLazyQuery(GET_NATIVE_CLASSIFICATION_COMPETENCY_2DEPTH_LIST, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      //테스트
      // enqueueSnackbar("2depth 역량 정보 조회 성공", {
      //   variant: "success",
      // });

      // console.log("2Depth 역량");
      // console.log(data?.classifications?.classifications);

      // classifications를 map으로 재구성
      let temp: ClassificationMap = new Map(data?.classifications?.classifications.map((item:any)=>[item.depth1Name, item]));

      temp?.forEach((value, key)=>{

        // 배열에 속한 역량에 해당되지 않는다면 제거 처리
        // 역량개발로드맵 > 과학기술인 역량체계 에 노출되는 역량만
        // 나의 관심 역량 설정을 할 수 있게 해달라는 요구사항때문에 추가
        if(!['핵심역량','직무역량','리더십역량'].includes(key)){
          temp.delete(key);
          return;
        };

        // depth2List가 배열이라면
        if(
          value &&
          typeof value === "object" &&
          "depth2List" in value &&
          Array.isArray(value?.depth2List)
          ){
          // 기존에 배열이었던 depth2List를 map으로 재구성
          let tempMap:ClassificationItemMap = new Map(value?.depth2List.map(item=>[item.depth2Id, item]));

          // isChecked, isInterestNew 값 생성
          tempMap?.forEach((value, key)=>{
            // 2 depth 데이터를 재구성
            // isChecked : 체크 여부
            // isInterestNew : 새롭게 관심 역량으로 추가되었는지 여부
            let newValue = {...value, isChecked:value.isInterest, isInterestNew:false};
            tempMap.set(key, newValue);
          })

          // value.depth2List에 tempMap을 넣으면 오류가 발생하기 때문에
          // set으로 새로운 value를 넣어주기 위해서 newValue 생성
          let newValue = {...value, depth2List:tempMap};
          temp.set(key, newValue);
        }

      })

      setDepth1List(temp);
    },
    onError: (error) => {
      console.log(error);
      enqueueSnackbar("2depth 역량 정보 조회에 실패하였습니다.", { variant: "error" });
    },
  });

  // 관심 역량 토글
  const [fnToggleMemberClassificationInterest] = useMutation(TOGGLE_MEMBER_CLASSIFICATION_INTEREST, {
    onCompleted: () => {
      enqueueSnackbar("관심 역량 저장에 성공하였습니다.", {
        variant: "success",
      });
      return true;
    },
    onError: (error) => {
      console.log(error);
      enqueueSnackbar("관심 역량 저장에 실패하였습니다.", { variant: "error" });
      return false;
    },
  });

  const getCptc = (memberId=undefined) => {
    let request: any = {
      memberId:memberId,
      companyGroupId:undefined,
      year:undefined,
      careerLevel:undefined,
      duty:undefined,
    };

    // alpha-campus
    // 주관처와 년도 정보는 어디서 가져와야 할까?
    request.companyGroupId = 'f5e6eb35-332a-498d-9e87-ca4727b1aa15';
    request.year = 2025;

    // 2depth 역량 조회
    fnGetNativeClassificationCompetency2DepthList({ variables: { request: { ...request } } });
  };

  // 담기
  const handleSubmit = (event:React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // const formData = new FormData(event.target as HTMLFormElement);
    //
    // // 체크된 역량들의 ID 목록
    // const checkedId = Object.keys(Object.fromEntries(formData.entries()));

    // 체크된 역량이 기존에 관심 역량이었는지 확인 후 새 관심 역량 field에 check하는 로직
    // 체크된 역량 반복
    newCheckList?.forEach(item=>{

      // depth1List 반복
      depth1List.forEach(depth1Cptc=>{
        
        // 체크된 역량의 값 불러오기
        let checkedCptc = depth1Cptc?.depth2List?.get(item);

        // 체크된 역량이 기존에 관심 역량이 아니었다면
        if(checkedCptc
          && !checkedCptc?.isInterest){
          checkedCptc.isInterestNew = true; // 새롭게 관심 역량이 된다는 isInterestNew에 true
        }
      })

    })
    setNewCheckList([]);

    // i : 1depth
    // j : 2depth
    depth1List?.forEach((i:ClassificationDepthResponse)=>{

      // 나의 관심 역량에 존재했지만 체크를 해제한 후 담기를 눌렀을 때
      // 나의 관심 역량에서 제거하기 위한 로직
      i.depth2List?.forEach((j:ClassificationItem, key:string)=>{

        // 나의 관심 역량에 존재하며 isChecked가 false라면 제거
        if(j.isChecked){
          myInterestCptc.set(key, j);
        }else{
          if(myInterestCptc.has(key)){
            myInterestCptc.delete(key);
          }
        }
      })
    })

    // 나의 관심 역량 영역에 넣기
    setMyInterestCptc(new Map(myInterestCptc));
  }

  // 전체 선택 외의 박스를 눌렀을 때 이벤트
  const handleChange = (key:string, isChecked:boolean) => {
    // isChecked 기존의 체크상태. 새롭게 바뀐 체크상태와 다르니 주의.

    // 체크된 항목을 depth2List에서 업데이트
    // 같은 참조값을 사용하기 때문에 dpeth1List에서도 같이 업데이트된다.
    // 기존 로직 문제점 : depth1List가 변경되지 않음
    // let newValue = {...depth2List.get(key), isChecked:!isChecked};
    // depth2List.set(key, newValue);
    // setDepth2List(new Map(depth2List));

    depth1List.get(depth1Name).depth2List.get(key).isChecked = !isChecked;

    // 체크 추가 항목을 새롭게 체크된 리스트에 추가
    // 체크 삭제 항목을 새롭게 체크된 리스트에서 제거
    let newCheckListTemp = [...newCheckList];
    if(!isChecked){
      newCheckListTemp.push(key);
    }else{
      newCheckListTemp = newCheckListTemp.filter(item=>item!=key);
    }
    setNewCheckList(newCheckListTemp);

    // 전체 선택 체크박스의 on, off 결정
    fnIsAllChecked(depth2List);
  }

  // 전체 선택 박스를 눌렀을 때 이벤트
  const handleSelectAll = (event:React.ChangeEvent<HTMLInputElement>) => {
    // 전체 선택 박스를 누를 때마다 박스의 checked에 맞춰서 isAllChecked의 상태 set
    const isChecked:boolean = event.target.checked;
    setIsAllChecked(isChecked);

    let newCheckListTemp = [...newCheckList];
    depth1List.get(depth1Name).depth2List?.forEach((value)=>{
      if(!value.isChecked && isChecked){
        newCheckListTemp.push(value);
        value.isInterestNew = true;
      }else{
        newCheckListTemp = newCheckListTemp.filter(item=>item.depth2Id!=value.depth2Id);
        value.isInterestNew = false;
      }
      value.isChecked = isChecked;
    })
    setNewCheckList(newCheckListTemp);
  }

  // 전체 체크 박스의 on off 결정
  const fnIsAllChecked = (depth2List:ClassificationItemMap) => {
    let onCount = 0;
    let depth2ListSize = depth2List?.size;
    depth2List?.forEach(item=>{
     if(item.isChecked) onCount++;
    })

    if(onCount==depth2ListSize){
     setIsAllChecked(true);
    }else{
     setIsAllChecked(false);
    }
  }

  const deleteInterest = (depth2Id:string) => {

    // 특정 ID를 나의 관심 역량 영역에서 제거
    myInterestCptc.delete(depth2Id);
    setMyInterestCptc(new Map(myInterestCptc));

    // depth1List, depth2List에서
    // 특정 ID를 체크 해제
    depth1List?.forEach((i:ClassificationDepthResponse)=>{
      i.depth2List?.forEach((j:ClassificationItem)=>{
        // 삭제한 ID와 같은 역량
        if(depth2Id==j.depth2Id){
          j.isChecked = false;

          // 현재 활성화 되어있는 1 Depth에 속한 2 Depth라면
          if(i.depth1Name==depth1Name){
            setDepth2List(i.depth2List);
          }
        }
      })
    })
  }

  // 저장
  const saveInterest = (memberId:string, myInterestCptc:ClassificationItemMap) => {
    let newInterest:ClassificationItemMap = new Map;
    myInterestCptc?.forEach((value:ClassificationItem, key:string)=>{
      if(value.isInterestNew) newInterest.set(key, value);
    });

    // request 구성
    let request:any = {
      id : memberId,
      classificationIds : [],
    }

    // 새롭게 추가된 관심 역량 저장
    newInterest?.forEach((item:ClassificationItem)=>{
      request.classificationIds.push(item.depth2Id);
    })

    // 삭제된 관심 역량 삭제
    depth1List?.forEach((depth1Cptc:ClassificationDepthResponse)=>{
      depth1Cptc.depth2List.forEach((depth2Cptc:ClassificationItem, depth2Id:string)=>{

        // 나의 관심 역량 영역에 존재하지 않으며
        // 기존에 관심 역량이었으며
        if(
        !myInterestCptc.has(depth2Id)
        && depth2Cptc.isInterest
        ){
          request.classificationIds.push(depth2Id);
        }
      })
    })

    let result = null;
    if(request.classificationIds.length>0){
      result = fnToggleMemberClassificationInterest({variables:{request:{...request}}});
    }

    // 추가 후 Depth1List, Depth2List 처리
    if(result){
      newInterest?.forEach((item:ClassificationItem)=>{
        // 이미 저장된 역량이 다시 API를 호출하게 하지 않도록 isInterestNew에 false 대입
        let depth2Cptc:ClassificationItem = myInterestCptc.get(item.depth2Id);
        // myInterestCptc의 값을 바꾸면 depth1List에서의 값도 바뀐다. 같은 참조값을 사용하고 있기 때문.
        depth2Cptc.isInterestNew = false;
        depth2Cptc.isInterest = true;
      })
      
      // 삭제 후 Depth1List, Depth2List 처리
      depth1List?.forEach((i:ClassificationDepthResponse)=>{
        i.depth2List.forEach((j:ClassificationItem, depth2Id:string)=>{
          // 나의 관심 역량 영역에 존재하지 않으며
          // 기존에 관심 역량이었으며
          if(
            !myInterestCptc.has(depth2Id)
            && j.isInterest
          ){
            // 삭제가 완료되었다면 depth1List에서의 isInterest false
            j.isInterest=false;
          }
        })
      })
    }

  }

  const reset = () => {
    setMyInterestCptc(new Map());
    getCptc(memberId);
    onClose();
  }

  useEffect(() => {
    getCptc(memberId);
  }, [memberId]);

  // 활성화된 1 Depth 역량
  useEffect(() => {
    if(depth1List instanceof Map && depth1List.size !== 0){
      setDepth2List(depth1List?.get(depth1Name).depth2List);
    }

    setIsAllChecked(false);
  }, [depth1Name]);

  // 1 Dpeth List가 설정되었을 때
  // API로 데이터를 불러왔을 때
  useEffect(() => {
    setDepth1Name(depth1List.keys().next().value);

    // DB에서 관심 역량으로 설정되어 있는 것을 나의 관심 역량 영역에 추가
    depth1List?.forEach(i=>{
      i.depth2List?.forEach((j:ClassificationItem)=>{
        if(j.isInterest){
          myInterestCptc.set(j.depth2Id, j);
        }
      })
    })

    if(depth1Name) setDepth2List(depth1List.get(depth1Name).depth2List);

    setMyInterestCptc(new Map(myInterestCptc));
  }, [depth1List]);

  useEffect(() => {
    fnIsAllChecked(depth2List);
  }, [depth2List]);

  return (
    <KirdModal title={t("나의 관심 역량 설정")} maxWidth="xl" open={open} onClose={()=>reset()} transitionComponent={Transition} {...other}>
      <DialogContent>
        <div className={styles.cdrPop}>
          <div className={styles.cdrStepCont}>
            <form onSubmit={handleSubmit}>
              <div className={styles.myCdrWrap}>
                <div className={styles.myCdrTabWrap}>
                  <ul className={styles.myCdrTab}>
                    {
                      Array.from(depth1List)?.map(item=>(
                        <li key={item[0]}>
                          <button
                            type="button"
                            className={clsx(styles.btnCdrTab, {[styles.active]: depth1Name == item[0]})}
                            onClick={() => setDepth1Name(item[0])}
                          >
                            <i style={
                              {
                                width: '44px',
                                height: '44px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }
                            }>
                              <img
                                alt={item[1]?.depth1Name ? item[1]?.depth1Name : ''}
                                src={item[1]?.depth1EmojiFile?.id ? `/api/file/${item[1]?.depth1EmojiFile?.id}?accessToken=ok` : '/images/icon/cdr/ico_cdr01.svg'}
                                style={{width: '40px', height: '40px', objectFit: 'cover'}}
                              />
                            </i>
                            <span className={styles.txtBtnLabel}>{item[1]?.depth1Name}</span>
                          </button>
                        </li>
                      ))
                    }
                  </ul>
                </div>

                <div className={styles.myCdrTabContWrap}>
                  <div className={styles.myCdrScrollWrap}>
                    <ul className={styles.myCdrList}>
                      <li>
                        <div className={styles.checkBox}>
                          <input
                            type="checkbox"
                            id="mcChkAll01"
                            checked={isAllChecked}
                            onChange={handleSelectAll}
                          />
                          <i className={styles.iconCheckBox}></i>
                          <label className={styles.checkLabel} htmlFor="mcChkAll01">전체선택</label>
                        </div>
                      </li>
                      {
                        Array.from(depth2List)?.map(item=>(
                          <li key={item[0]}>
                            <div className={styles.checkBox}>
                              <input
                                type="checkbox"
                                id={item[0]}
                                name={item[0]}
                                checked={item[1]?.isChecked}
                                onChange={()=>handleChange(item[0], item[1]?.isChecked)}
                              />
                              <i className={styles.iconCheckBox}></i>
                              <label className={styles.checkLabel} htmlFor={item[0]}>{item[1]?.depth2Name}</label>
                            </div>
                            <div className={styles.cdrDeskLayer}>
                              <span className={styles.txtDesk}>{item[1]?.depth2Desc?item[1]?.depth2Desc:"상세 설명이 없습니다."}</span>
                            </div>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
              </div>

              <div className={styles.bottomBtnWrap}>
                <button type="submit" className={styles.btnBlue}>담기</button>
              </div>
            </form>

            <div className={styles.myCdrBox}>
              <strong className={styles.titMyCdr}>나의 관심 역량</strong>
              <div className={styles.mcList}>
                {Array.from(myInterestCptc)?.map(item=>(
                  <div className={styles.cdrLabel}>
                    <span className={styles.txtLabel}>{item[1]?.depth2Name}</span>
                    <button
                      type="button"
                      className={styles.btnIcon_X}
                      onClick={()=>deleteInterest(item[0])}
                    >
                      <span className={styles.ir}>삭제</span>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={styles.cdrPop}>
          <div className={styles.bottomBtnWrap}>
            <button type="button" className={styles.btnGray} onClick={()=>reset()}>취소</button>
            <button type="button" className={styles.btnBlue} onClick={()=>saveInterest(memberId, myInterestCptc)}>저장</button>
          </div>
        </div>
      </DialogActions>
    </KirdModal>
  )
}