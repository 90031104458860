import React from 'react';
import CdrSettingPopup from "partials/capacityDev/cdrSettingPopup";
import { useState, useEffect } from "react";
import { parseCookies, setCookie } from "nookies";
import axios from "axios";
import { gql, useLazyQuery} from "@apollo/client";
import { useUser } from "shared/api/useUser";
import { useRouter } from "next/router";
import { MemberFormType } from "pages/smedu/member/create";
import MyCdrPopup from "./myCdrPopup";

// 회원 정보 조회
const GET_MEMBER_BY_ID = gql`
  query GetMemberById($id: ID!) {
    member: getMemberById(id: $id) {
      id
      name
      jobField
      careerLevel
      createdDateTime
    }
  }
`;

// 로그인 이력 목록 조회
const GET_LOGIN_HISTORY_LIST = gql`
  query GetLoginHistoryList($request: GetLoginHistoryListInput) {
    loginHistories: getLoginHistoryList(request: $request) {
      id
      user {
        id
        name
      }
      loginDate
      lastLoginTime
      loginCount
    }
  }
`;

// 내정보화면 유저 타입
interface MemberEditFormType extends MemberFormType {
  type: string;
  birthday: string;
  createdDateTime: Date;
}

export default function CheckMemberCdr(){

  // 쿠키 불러오기
  let cookies = parseCookies();

  // 쿠키 이름 설정
  const cookieName = "openCareerLevelPopup";

  // 유저 정보 체크 플래그 상태 설정
  const [checkFlag, setCheckFlag] = useState(false);

  // 팝업창 띄우는 상태 설정
  const [openCareerLevelSetting, setOpenCareerLevelSetting] = useState(false);

  // query, user
  const { query, isReady } = useRouter();
  const user = useUser();

  // 회원 정보
  const [member, setMember] = useState<MemberEditFormType>();

  // 회원 정보 변경 이력
  const [memberHistory, setMemberHistory] = useState<[]>();
  
  // 나의 관심 역량 설정
  const [openMyCdr, setOpenMyCdr] = useState(false);

  // 회원의 가입 일자와 비교 대상이 되는 특정 일자
  const targetDate = new Date('2024-12-31');

  // 나의 관심 역량 설정 팝업을 닫을 때 사용할 쿠키 이름
  const closeMyCdrCookieNmae = "closeMyCdrCookie";

  function onClose(){
    setOpenCareerLevelSetting(false);
  }


  function fnCloseMyCdrCookie(){

    setCookie(null, closeMyCdrCookieNmae, "closed", {
      maxAge: 7*24*60*60,
      path: '/',
    });

  }

  // 회원 정보 조회
  const [fnGetMember] = useLazyQuery(GET_MEMBER_BY_ID, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.member) {
        setMember(data.member);
      }
    },
  });

  // 회원 정보 변경 이력 조회
  const fnSearchOneMemberHistory = (request:{memberId : string}) => {
    axios.post(`/api/memberHistory/searchOneMemberHistory`, request, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      if(response?.data) setMemberHistory(response.data);
    });
  }

  // 로그인 이력 목록 조회
  const [fnGetLoginHistoryList] = useLazyQuery(GET_LOGIN_HISTORY_LIST, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {

      // 최초 로그인인지 검사하여 팝업창을 띄우기 위한 status를 활성화하는 로직

      // 로그인 일자가 하루라면
      if(data?.loginHistories.length<2){

        // 팝업창을 닫았었는지 확인하기 위한 쿠키
        const cookies = parseCookies();
        const MyCdrCookie = cookies[closeMyCdrCookieNmae];

        // 해당 로그인 일자에 해당하는 로그인 횟수가 1회라면
        // 팝업창을 한 번 닫았다면
        // 최초 로그인
        if(data?.loginHistories[0]?.loginCount==1
        && MyCdrCookie==undefined){
          console.log("first login");
          setOpenMyCdr(true);
        }
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(()=>{
    // 쿠키 여부에 따라 팝업 오픈 플래그 설정
    cookies = parseCookies();
    if (cookies[cookieName] !== undefined && checkFlag == false){
      setCheckFlag(true);
    }else{
      setCheckFlag(false);
    }
  },[]);

  useEffect(() => {
    if (isReady) {
      if (user.data?.user?.id) {

        // 로그인 이력 조회
        // 나의 관심 역량 설정 팝업을 띄울지 판별하기 위한
        // 로그인 이력의 존재 여부
        fnGetLoginHistoryList({variables: {request: {studentId: user.data?.user?.id}}});

        // 회원 정보 조회
        // 회원 정보 변경 이력 팝업을 띄울지 판별하기 위한
        // 가입 일자 정보가 필요
        fnGetMember({ variables: { id: user.data?.user?.id } });

        // 회원 정보 변경 이력 조회
        // 회원 정보 변경 이력 팝업을 띄울지 판별하기 위한
        // 변경 이력의 존재 여부
        let request = {memberId : user.data?.user?.id};
        fnSearchOneMemberHistory(request);
      }
    }
  }, [query, user]);

  useEffect(()=>{
    // 팝업을 띄울지 정하는 플래그가 true라면
    if(checkFlag){

      // 회원의 가입 일자가 불러와진 상태이며
      // 회원 정보 변경 이력이 존재하지 않는다면
      if(member?.createdDateTime
        && memberHistory?.length<1){
        // 회원의 가입 일자로 Date 객체 생성
        const createdDateTime = new Date(member.createdDateTime);
        // 특정 일자 이전에 가입한 회원이라면
        if(createdDateTime<targetDate) setOpenCareerLevelSetting(true);

      // 회원 정보가 불러져와 있는 상태이며
      // 직무 또는 경력단계의 정보가 없다면
      }else if(member !== undefined
        && (member?.jobField == undefined ||
        member?.careerLevel == undefined ||
        member?.jobField == "" ||
        member?.careerLevel == "")
        ){
        setOpenCareerLevelSetting(true);
      }

      /* Skip Flag가 체크되어있다면 팝업이 뜨지 않도록 설정 */
      if(cookies["careerLevelSettingSkip"]=="1") setOpenCareerLevelSetting(false)
    }
  }, [checkFlag, member, memberHistory]);

  useEffect(()=>{
  }, [openCareerLevelSetting]);


  return (
    <>
      {openCareerLevelSetting && (
        <CdrSettingPopup fullWidth={true} maxWidth='md' open={openCareerLevelSetting} onClose={onClose} openPopupCookieName={cookieName}/>
      )}

      {openMyCdr && (
        // 최초 로그인인 경우에만 렌더링되도록 하기 위한 조건문
        <MyCdrPopup
          fullWidth={true}
          maxWidth='md'
          open={openMyCdr}
          onClose={() => {
            setOpenMyCdr(false)
            fnCloseMyCdrCookie();
          }}
          memberId={member?.id}
        />
      )}
    </>
  );
};